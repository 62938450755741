












import { Component, Vue } from "vue-property-decorator";
import Tabs from "@/components/common/Tabs.vue";
import { Tab } from "@/interfaces";
import { IndustryType } from "@/entity/Industry";

@Component({
  components: {
    Tabs,
  },
})
export default class DictionariesView extends Vue {
  get tabs(): Tab[] {
    return [
      {
        routeName: "dictionaryIndustries",
        name: this.$tc("industry", 1),
      },
      {
        routeName: "dictionarySubIndustries",
        name: this.$tc("subIndustry", 1),
      },
      {
        routeName: "dictionaryFunctionalExperience",
        name: this.$tc("functionalExperience", 1),
      },
    ];
  }

  get activeIndustryTypeTab(): IndustryType | null {
    if (this.$route.name === "dictionaryIndustries") {
      return IndustryType.INDUSTRY;
    } else if (this.$route.name === "dictionarySubIndustries") {
      return IndustryType.SUB_INDUSTRY;
    } else if (this.$route.name === "dictionaryFunctionalExperience") {
      return IndustryType.FUNCTIONAL_EXPERIENCE;
    }

    return null;
  }
}
